import React, { useEffect, useState, useRef } from "react";
import { AppHelper } from '../../AppHelper';
import {
  Button, Box, Card, Dialog, DialogTitle, Grid, IconButton,
  Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Tooltip
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { pink, green } from '@mui/material/colors';
import { diffWordsWithSpace } from 'diff';

export default function PopVCAErrors ({
  onClose, open, scoreInfo, essay, allowMark, onMarkDone
}){
  const [errorList, setErrorList] = useState([]);
  useEffect(()=>{
    updateHighlight();
  }, [essay, scoreInfo]);
  // useEffect(()=>{
  //   updateHighlight();
  // }, [open]);
  const updateHighlight = () => {
    if(!scoreInfo || !scoreInfo.errors || scoreInfo.errors.length<1) return;
    var _errList = [...scoreInfo.errors];
    _errList.forEach(r => {
      var _w = `<span style="background:#ffe0b2; font-weight:bold">${r.w}</span>`; //#e57373
      var regex = new RegExp(r.w, 'g');
      r.wl_hilight = r.wl.replace(regex, _w);

      var _wEX = `'<span style="background:#ffe0b2; font-weight:bold">${r.w}</span>'`; //#e57373
      var regexEX = new RegExp(`'${r.w}'`, 'g');
      r.ex_hilight = r.ex.replace(regexEX, _wEX);

      var regexEX2 = new RegExp(`'${r.sg}'`, 'g');
      _w = `'<span style="background:#1de9b6; font-weight:bold">${r.sg}</span>'`; //#4caf50
      r.ex_hilight = r.ex_hilight.replace(regexEX2, _w);
    });
    setErrorList(_errList);
  }
  const handleClose = () =>{ onClose() };
  
  const onFalseClick = async(rowIndex, argValue) => {
	  var _user = AppHelper.userCacheGet();
      var _url = 'api/score/VC/error/false'; 
	  var _payload = {token: _user.token, essayId: essay.essayId, errorIndex: rowIndex, isFalse:argValue, profileId: _user.profileId};
	  
	  var _res = await AppHelper.apiPost(_url, _payload);
	  if (!_res || !_res.status) {
		alert('ERROR: Failed to set false!');
		return;
	  }
    //alert("Success");
    var _err = errorList[rowIndex];
    _err.isFalse = argValue;
    var _errorCount = errorList.filter(x=>x.isFalse!=1).length;
    if(onMarkDone) onMarkDone(_errorCount);
	};
  
  return(
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>VOCABULARY MISTAKES</DialogTitle>
      <Box padding={'0.5rem'}>
        { errorList.length>0 &&
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 550 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{fontWeight:'bold'}}>Incorrect Usage Word</TableCell>
                <TableCell sx={{fontWeight:'bold'}}>Incorrect Line</TableCell>
                <TableCell sx={{fontWeight:'bold'}}>Suggested Correction</TableCell>
                <TableCell sx={{fontWeight:'bold'}}>Explanation</TableCell>
                <TableCell sx={{fontWeight:'bold'}}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { errorList.map((row, iRow)=>(
                <TableRow key={`tr_${iRow}_E`} style={{textDecoration:row.isFalse==1?'line-through':''}}>
                  <TableCell>{row.w}</TableCell>
                  <TableCell dangerouslySetInnerHTML={{ __html:row.wl_hilight}}>
                    {/* {row.wl} */}
                  </TableCell>
                  <TableCell>{row.sg}</TableCell>
                  <TableCell dangerouslySetInnerHTML={{ __html:row.ex_hilight}}>
                    {/* {row.ex} */}
                  </TableCell>
                  <TableCell>
                    { allowMark && row.isFalse != 1 && 
                    <Tooltip title="Mark as False Positive">
                      <IconButton onClick={() => onFalseClick(iRow, 1)} area-label="Mark as False Positive">
                        <RemoveCircleOutlineIcon style={{color:pink[500]}} />
                      </IconButton>
                    </Tooltip>
                    }
                    { allowMark && row.isFalse == 1 && 
                    <Tooltip title="Mark as Positive">
                      <IconButton onClick={() => onFalseClick(iRow, 0)} area-label="Re-mark as Positive">
                        <TaskAltIcon style={{color:green[500]}} />
                      </IconButton>
                    </Tooltip>
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        }
      </Box>
    </Dialog>
  );
}